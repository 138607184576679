<template>
  <div>
    <section class="hero united-hero is-75percent" style="padding-top: 0px">
      <!-- Hero head: will stick at the top -->
      <div class="hero-head">
        <header class="navbar">
          <div class="container">
            <div class="navbar-brand">
              <a class="navbar-item">
                <img src="../assets/united-logo.png" alt="Logo" style="max-height: 2.75rem" />
              </a>
              <span class="navbar-burger" data-target="navbarMenuHeroC">
                <span></span>
                <span></span>
                <span></span>
              </span>
            </div>
            <div id="navbarMenuHeroC" class="navbar-menu">
              <div class="navbar-end">
                <a class="navbar-item" href="https://discord.gg/SEExt8qqkb"> Discord </a>
                <a class="navbar-item" href="https://vamsys.io/register/virtualunited"> Signup </a>
                <a class="navbar-item" href="https://vamsys.io/login/virtualunited"> Login </a>
              </div>
            </div>
          </div>
        </header>
      </div>

      <!-- Hero content: will be in the middle -->
      <div class="hero-body" style="position: relative">
        <div class="container has-text-centered">
          <p class="title" style="font-family: 'Montserrat', sans-serif; font-size: 70px; color: #0054a6">Fly the Friendly Skies</p>
          <p class="subtitle" style="font-family: 'Montserrat', sans-serif; font-size: 20px; color: white">
            The most varied fleet, the most varied destinations. Virtual United is the place to fly
          </p>
        </div>
        <div class="container has-text-centered" style="position: absolute; bottom: 50px; left: 0; right: 0">
          <b-button tag="a" href="https://vamsys.io/register/virtualunited" type="is-info" style="padding: 28px; font-size: 24px">Click here to join now!</b-button>
        </div>
      </div>
    </section>
    <section style="margin-top: -65px; padding-top: 0px">
      <div class="container" style="z-index: 1">
        <div class="columns">
          <div class="column">
            <div class="box">
              <p class="heading">Pilots</p>
              <p class="title">{{ this.vamsysData.pilots.total }}</p>
            </div>
          </div>
          <div class="column">
            <div class="box">
              <p class="heading">Total Flight Time</p>
              <p class="title">{{ this.vamsysData.flight_time.total_formatted }}</p>
            </div>
          </div>
          <div class="column">
            <div class="box">
              <p class="heading">Pireps</p>
              <p class="title">{{ this.vamsysData.pireps.total }}</p>
            </div>
          </div>
          <div class="column">
            <div class="box">
              <p class="heading">Aircraft</p>
              <p class="title black">{{ this.vamsysData.general.aircraft }}</p>
            </div>
          </div>
          <div class="column">
            <div class="box">
              <p class="heading">Routes</p>
              <p class="title black">{{ this.vamsysData.general.routes }}</p>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section style="background-color: rgb(238 238 238); margin-top: -70px; padding-top: 75px">
      <div class="container">
        <div class="columns is-centered">
          <div class="column has-text-centered">
            <h1 class="title">Why fly for us?</h1>
          </div>
        </div>
        <div class="columns is-centered is-multiline">
          <div class="column is-one-third">
            <div class="box">
              <img src="http://www.virtualcargolux.org/home/wp-content/uploads/vAMSYSBig-Black.png" />
              <div class="meta">
                <h1 class="title is-5">Vamsys</h1>
                <h2 class="subtitle is-6">Virtual Airline Management System</h2>
              </div>
            </div>
          </div>
          <div class="column is-one-third">
            <div class="box">
              <div class="columns" style="align-items: center">
                <div class="column"><img src="https://assets-global.website-files.com/5b27a494dc3d3103abc01d83/5f125d35c716627ceba80043_MFS%20Logo.png" /></div>
                <div class="column"><img src="https://upload.wikimedia.org/wikipedia/commons/thumb/5/5d/X-Plane_11_logo.svg/1280px-X-Plane_11_logo.svg.png" /></div>
                <div class="column"><img src="https://upload.wikimedia.org/wikipedia/commons/7/72/P3D_logo_wikipedia.png" /></div>
                <div class="column"><img src="https://www.alabeo.com/sitealabeo/wp-content/uploads/2018/03/fsx-1.png" /></div>
              </div>
              <div class="meta">
                <h1 class="title is-5">Support for all flight simulators</h1>
                <h2 class="subtitle is-6">MSFS2020, XPlane, P3D and FSX</h2>
              </div>
            </div>
          </div>
          <div class="column is-one-third">
            <div class="box">
              <img src="https://www.aimstyle.com/images/blogs/team.jpg" />
              <div class="meta">
                <h1 class="title is-5">Friendly and Active Staff Team</h1>
                <h2 class="subtitle is-6"></h2>
              </div>
            </div>
          </div>
          <div class="column is-one-third">
            <div class="box">
              <img src="https://ychef.files.bbci.co.uk/976x549/p08k8d39.jpg" />
              <div class="meta">
                <h1 class="title is-5">Aircraft Agnostic</h1>
                <h2 class="subtitle is-6">Our aircraft are split into three classes and each route has a class</h2>
              </div>
            </div>
          </div>
          <div class="column is-one-third">
            <div class="box">
              <img src="https://images.pexels.com/photos/2064123/pexels-photo-2064123.jpeg" />
              <div class="meta">
                <h1 class="title is-5">Cater to all pilot types</h1>
                <h2 class="subtitle is-6">Whether you are a casual or a airline pilot, everybody is welcome!</h2>
              </div>
            </div>
          </div>
          <div class="column is-one-third">
            <div class="box">
              <img src="https://www.mpooleremovals.co.uk/wp-content/uploads/2019/12/packing-boxes-derby-1-300x300.jpg" />
              <div class="meta">
                <h1 class="title is-5">Resources</h1>
                <h2 class="subtitle is-6">We have sound and livery packs avaliable to download</h2>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section>
      <div class="container">
        <div class="columns is-centered">
          <div class="column has-text-centered">
            <h1 class="title">About Us</h1>
            <p>
              Virtual United is a virtual airline set on recreating the operations of the real United Airlines. Using our vAMSYS crew management system, we allow pilots of all
              abilities to fly some of the most busiest and stunning routes.
              <br />
              <br />
              Virtual United provides for both those who want to take it seriously, with real-world routes and flight planning, and to those who like a more laid back experience We
              have no activity requirements and do no lock any aircraft behind annoying rank barriers!
            </p>
          </div>
        </div>
      </div>
    </section>
    <section style="background-color: rgb(238 238 238)">
      <h1 class="title">Current Live Flights: {{ players.length }}</h1>
      <h2 class="subtitle">View our pilots who are currently flying in the friendly skies</h2>
      <l-map style="height: 400px; width: 100%" :zoom="zoom" :center="center" @update:zoom="zoomUpdated" @update:center="centerUpdated" @update:bounds="boundsUpdated">
        <l-tile-layer :url="url"></l-tile-layer>
        <l-marker v-for="player in players" v-bind:key="player" :lat-lng="player" :icon="planeIcon"></l-marker>
      </l-map>
    </section>
    <section>
      <div class="container">
        <div class="columns is-centered">
          <div class="column has-text-centered">
            <h1 class="title">Our Bases</h1>
            <h2 class="subtitle">
              Virtual United recreates United's hub-and-spoke model, meaning almost all of our flights operate to or from one of our main hubs. This means wherever you are, you are
              never far away from a hub with hundreds of destinations to fly to.
            </h2>
          </div>
        </div>
        <div class="columns is-centered is-multiline">
          <div class="column is-one-quarter">
            <div class="box">
              <img src="https://cdn.businesstraveller.com/wp-content/uploads/2018/12/Chicago-airport-e1545896361983.png" />
              <div class="meta">
                <h1 class="title is-5">Chicago O'Hare International Airport</h1>
                <h2 class="subtitle is-6">KORD</h2>
              </div>
            </div>
          </div>
          <div class="column is-one-quarter">
            <div class="box">
              <img src="https://www.tripsavvy.com/thmb/TnrG8_7Oo8916w0UxrvieT8-5ek=/1777x1333/smart/filters:no_upscale()/newark-airport-aerial-565a1a245f9b5835e464f9c4.jpg" />
              <div class="meta">
                <h1 class="title is-5">Newark Liberty International Airport</h1>
                <h2 class="subtitle is-6">KEWR</h2>
              </div>
            </div>
          </div>
          <div class="column is-one-quarter">
            <div class="box">
              <img src="https://upload.wikimedia.org/wikipedia/commons/9/92/Washington_Dulles_International_Airport_at_Dusk.jpg" />
              <div class="meta">
                <h3 class="title is-5">Dulles International Airport</h3>
                <h4 class="subtitle is-6">KIAD</h4>
              </div>
            </div>
          </div>
          <div class="column is-one-quarter">
            <div class="box">
              <img src="https://s28477.pcdn.co/wp-content/uploads/2018/01/houston_george_bush_9.jpg" />
              <div class="meta">
                <h3 class="title is-5">George Bush Intercontinental Airport</h3>
                <h4 class="subtitle is-6">KIAH</h4>
              </div>
            </div>
          </div>
          <div class="column is-one-quarter">
            <div class="box">
              <img src="https://archinect.imgix.net/uploads/6e/6ee283e4214ff7f897d13e724cde4ede.jpg?auto=compress%2Cformat" />
              <div class="meta">
                <h3 class="title is-5">Los Angeles International Airport</h3>
                <h4 class="subtitle is-6">KLAX</h4>
              </div>
            </div>
          </div>
          <div class="column is-one-quarter">
            <div class="box">
              <img
                src="https://www.tripsavvy.com/thmb/MXLm6mWvUKgHEkc8wZdUahazPrw=/3859x2171/smart/filters:no_upscale()/usa--california--san-francisco--san-francisco-international-airport-200368750-001-5a65101722fa3a0037bf21a0.jpg"
              />
              <div class="meta">
                <h3 class="title is-5">San Francisco International Airport</h3>
                <h4 class="subtitle is-6">SFO</h4>
              </div>
            </div>
          </div>
          <div class="column is-one-quarter">
            <div class="box">
              <img src="https://blog.blacklane.com/wp-content/uploads/2019/07/Untitled-design-2019-07-24T095857.805.png" />
              <div class="meta">
                <h3 class="title is-5">Denver International Airport</h3>
                <h4 class="subtitle is-6">KDEN</h4>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section style="background-color: rgb(238 238 238)">
      <div class="container">
        <div class="columns is-centered">
          <div class="column has-text-centered">
            <h1 class="title">Our Fleet</h1>
            <h2 class="subtitle">
              Virtual United operate one of the most varied fleets from short haul to long-range wide-bodied aircraft. We are confident you will find a plane you just love to fly.
            </h2>
          </div>
        </div>
        <div class="columns is-centered is-multiline">
          <div class="column is-one-quarter">
            <div class="box">
              <img src="https://i2.wp.com/airwaysmag.com/wp-content/uploads/2019/10/UA-550-1024x683.jpg?resize=1024%2C683&ssl=1" />
              <div class="meta">
                <h1 class="title is-5">Bombardier CRJ200/550/700</h1>
                <h2 class="subtitle is-6"></h2>
              </div>
            </div>
          </div>
          <div class="column is-one-quarter">
            <div class="box">
              <img src="https://worldairlinenews.files.wordpress.com/2014/05/united-express-erj-175-pr-efc-co-91fltembraer-unitedlrw.jpg?w=625" />
              <div class="meta">
                <h1 class="title is-5">Embraer 170/175</h1>
                <h2 class="subtitle is-6"></h2>
              </div>
            </div>
          </div>
          <div class="column is-one-quarter">
            <div class="box">
              <img src="https://theflight.info/wp-content/uploads/2016/04/n17229-united-airlines-boeing-737-824wl.jpg" />
              <div class="meta">
                <h3 class="title is-5">Boeing 737-700/800</h3>
                <h4 class="subtitle is-6"></h4>
              </div>
            </div>
          </div>
          <div class="column is-one-quarter">
            <div class="box">
              <img src="https://aviationvoice.com/wp-content/uploads/2018/10/United-Airbus-A320-200-Engine-Shut-Down-in-Flight.jpg" />
              <div class="meta">
                <h3 class="title is-5">Airbus A320</h3>
                <h4 class="subtitle is-6"></h4>
              </div>
            </div>
          </div>
          <div class="column is-one-quarter">
            <div class="box">
              <img src="https://theflight.info/wp-content/uploads/2016/04/n548ua-united-airlines-boeing-757-222.jpg" />
              <div class="meta">
                <h3 class="title is-5">Boeing 757-200/300</h3>
                <h4 class="subtitle is-6"></h4>
              </div>
            </div>
          </div>
          <div class="column is-one-quarter">
            <div class="box">
              <img src="https://aviationvoice.com/wp-content/uploads/2019/02/United-Boeing-767-300-1200x675.jpg" />
              <div class="meta">
                <h3 class="title is-5">Boeing 767-300/400ER</h3>
                <h4 class="subtitle is-6"></h4>
              </div>
            </div>
          </div>
          <div class="column is-one-quarter">
            <div class="box">
              <img src="https://a57.foxnews.com/static.foxnews.com/foxnews.com/content/uploads/2019/06/931/524/united-airlines-istock.jpg?ve=1&tl=1" />
              <div class="meta">
                <h3 class="title is-5">Boeing 777-200/300ER</h3>
                <h4 class="subtitle is-6"></h4>
              </div>
            </div>
          </div>
          <div class="column is-one-quarter">
            <div class="box">
              <img src="https://upload.wikimedia.org/wikipedia/commons/8/8c/N26902_-_Boeing_787-8_Dreamliner_-_United_Airlines.jpg" />
              <div class="meta">
                <h3 class="title is-5">Boeing 787-8/9/10</h3>
                <h4 class="subtitle is-6"></h4>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section>
      <div class="container">
        <div class="columns is-centered">
          <div class="column has-text-centered">
            <h1 class="title">Meet The Team</h1>
            <h2 class="subtitle">See who helps to run Virtual United</h2>
          </div>
        </div>
        <div class="columns is-centered is-multiline">
          <div class="column is-one-quarter">
            <div class="box">
              <img class="team-img" src="https://cdn.discordapp.com/avatars/358627761419583513/bd2127720a21849d85812d9c16e00e03.webp" />
              <div class="meta">
                <h1 class="title is-5">Gary Walker</h1>
                <h2 class="subtitle is-6">CEO</h2>
              </div>
            </div>
          </div>
          <div class="column is-one-quarter">
            <div class="box">
              <img class="team-img" src="https://cdn.discordapp.com/avatars/264116778504814592/4b1e6300286130a1af199e27df9d9cc9.webp" />
              <div class="meta">
                <h1 class="title is-5">Charlie Cobb</h1>
                <h2 class="subtitle is-6">Chairman</h2>
              </div>
            </div>
          </div>
          <div class="column is-one-quarter">
            <div class="box">
              <img class="team-img" src="https://cdn.discordapp.com/avatars/293115394435186701/a52357c499a7a3ff1764766fbf1bf0f1.webp" />
              <div class="meta">
                <h3 class="title is-5">Quint Van den Hazel</h3>
                <h4 class="subtitle is-6">Fleet Director</h4>
              </div>
            </div>
          </div>
          <div class="column is-one-quarter">
            <div class="box">
              <img class="team-img" src="https://cdn.discordapp.com/avatars/406205105994465281/b961ecd38ce67af6554b12cf0fe71dc3.webp" />
              <div class="meta">
                <h1 class="title is-5">Evan McLaughlin</h1>
                <h2 class="subtitle is-6">Routes Director</h2>
              </div>
            </div>
          </div>
          <div class="column is-one-quarter">
            <div class="box">
              <img class="team-img" src="https://cdn.discordapp.com/avatars/159254780428943360/d4654daa23c89ed5909bc560adab948c.webp" />
              <div class="meta">
                <h1 class="title is-5">Joe Garlick</h1>
                <h2 class="subtitle is-6">Director of Technology</h2>
              </div>
            </div>
          </div>
          <div class="column is-one-quarter">
            <div class="box">
              <img class="team-img" src="https://cdn.discordapp.com/avatars/104291860297564160/f198b1b63ae4ba3973df48f014fca56d.webp" />
              <div class="meta">
                <h3 class="title is-5">Jon Hawke</h3>
                <h4 class="subtitle is-6">Director of Events</h4>
              </div>
            </div>
          </div>
          <div class="column is-one-quarter">
            <div class="box">
              <img class="team-img" src="https://cdn.discordapp.com/avatars/697064945057005618/df69481d7645d440f051088bad740468.webp" />
              <div class="meta">
                <h3 class="title is-5">Dave</h3>
                <h4 class="subtitle is-6">Area Manager</h4>
              </div>
            </div>
          </div>
          <div class="column is-one-quarter">
            <div class="box">
              <img class="team-img" src="https://cdn.discordapp.com/avatars/157259327466373120/cb48435613fa9846f8f1248ac273288f.webp" />
              <div class="meta">
                <h3 class="title is-5">Konrad</h3>
                <h4 class="subtitle is-6">Area Manager</h4>
              </div>
            </div>
          </div>
          <div class="column is-one-quarter">
            <div class="box">
              <img class="team-img" src="https://cdn.discordapp.com/avatars/514493769098199040/b7ed2f01198ec70c1ad0bd2a8e95464f.webp" />
              <div class="meta">
                <h3 class="title is-5">Miguel</h3>
                <h4 class="subtitle is-6">Area Manager</h4>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <footer class="footer">
      <div class="content has-text-centered">
        <p><strong>Virtual United</strong> by <a href="https://garlickbread.com">GarlickBread</a></p>
        We are not affiliated with the real world United Airlines
      </div>
    </footer>
    <b-modal v-model="isModalActive" has-modal-card trap-focus :destroy-on-hide="true">
      <div class="card" style="background-color: rgb(0, 84, 166)">
        <div style="text-align: end; padding: 20px">
          <button type="button" class="delete" v-on:click="isModalActive = false" />
        </div>
        <div class="card-content" style="margin-top: -60px">
          <img src="../assets/united-logo-inverted.png" alt="Logo" style="max-height: 6rem" />
          <div class="media">
            <div class="media-content">
              <p class="title is-4" style="color: white">We are Virtual United</p>
            </div>
          </div>

          <div class="content" style="color: white">
            This is a virtual airline just for flight simulators,<br />
            if you are looking for the real United Airlines press the button below
          </div>
          <b-button type="is-success" v-on:click="isModalActive = false" expanded>Enter Website</b-button>
          <br />
          <b-button tag="a" href="https://www.united.com/" type="is-warning" expanded>Go to United Airlines</b-button>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
const axios = require("axios");

import L from "leaflet";
import { LMap, LTileLayer, LMarker } from "vue2-leaflet";
export default {
  components: {
    LMap,
    LTileLayer,
    LMarker,
  },
  name: "Home",
  data: function () {
    return {
      vamsysData: {},
      url: "https://api.maptiler.com/maps/topo/{z}/{x}/{y}.png?key=YuGSGp113Q4GXk91JnFl",
      zoom: 5,
      center: [37.80891512504096, -98.57306452085587],
      bounds: null,
      players: [],
      planeIcon: L.icon({
        iconUrl: "/plane_icon.png",
        iconSize: [32, 32],
        iconAnchor: [16, 16],
      }),
      isModalActive: true,
    };
  },

  mounted: async function () {
    this.GetVamsysStats();
    this.GetPlayers();
  },
  methods: {
    async GetVamsysStats() {
      const res = await axios.get("https://vamsys.io/statistics/5b0ac83e-ee3a-4f0a-9495-85ab49df22d6");

      this.vamsysData = res.data;
    },
    async GetPlayers() {
      const res = await axios.get("https://vamsys.io/statistics/map/5b0ac83e-ee3a-4f0a-9495-85ab49df22d6");
      for (const player of res.data) {
        this.players.push([player.currentLocation.latitude, player.currentLocation.longitude]);
      }
    },
    zoomUpdated(zoom) {
      this.zoom = zoom;
    },
    centerUpdated(center) {
      this.center = center;
    },
    boundsUpdated(bounds) {
      this.bounds = bounds;
    },
  },
};
</script>

<style scoped>
html {
  font-family: "Montserrat", sans-serif;
}
a {
  font-family: Montserrat, sans-serif;
}
.hero.is-75percent {
  min-height: 75vh;
}
.united-hero {
  background-image: url("https://cdn.discordapp.com/attachments/553230176775831552/859160109979271168/Microsoft_Flight_Simulator_Screenshot_2021.06.26_-_13.36.24.10_2.png");
  color: #fff;
  background-size: cover;
  background-position-x: center;
  background-position-y: center;
}
.black {
  color: black;
}
#map {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.vue2leaflet-map {
  z-index: 0 !important;
}

a.navbar-item,
.navbar-link {
  color: white;
}

a.navbar-item:focus,
a.navbar-item:focus-within,
a.navbar-item:hover,
a.navbar-item.is-active,
.navbar-link:focus,
.navbar-link:focus-within,
.navbar-link:hover,
.navbar-link.is-active {
  background-color: #fafafa00;
  color: rgb(0, 84, 166);
}

.team-img {
  border-radius: 50%;
}

section {
  padding-top: 25px;
  padding-bottom: 25px;
}
.box {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  height: 100%;
}
</style>
